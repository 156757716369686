import React from 'react'
import { PageLayout } from 'components/common'

export default function Home() {
  return (
    <PageLayout>
      <h1>Home</h1>
      <p>
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis
        facilis aliquam sint nihil? Unde ea ducimus aperiam autem fugit,
        suscipit nisi molestias nesciunt eos vero, nobis error, eum vitae
        accusantium.
      </p>
    </PageLayout>
  )
}
